import React, { useState, useEffect, useCallback } from "react";

import "./App.css";
import { ZoomMtg } from "@zoomus/websdk";
import logo from "./tss_logo.png";
import image from "./image.jpeg";
import loader from "./assets/compressjpeg/loader-min.gif";
import { useLocation } from "react-router-dom";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.10.0/lib", "/av");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

const authEndpoint =
  "https://thesmilingsouls.com/custom/index.php/api/v1/user/token";
const sdkKey = "Xt7PWHdSQvhnylHiEyDTw";
const registrantToken = "";
const zakToken = "";
const leaveUrl = "https://thesmilingsouls.com";

const App = () => {
  const [meetingNumber, setMeetingNumber] = useState();
  const [username, setUserName] = useState();
  const location = useLocation();
  useEffect(() => {
    const meetingIdAndUsername = location.hash?.split("/");
    const meetingId = meetingIdAndUsername[1];
    const userName = decodeURI(meetingIdAndUsername[2]);
    try {
      setMeetingNumber(meetingId);
      setUserName(userName);
    } catch (error) {
      console.log(error);
    }
    return () => {};
  }, [location?.hash, setMeetingNumber, setUserName]);

  const startMeeting = useCallback(
    (signature) => {
      document.getElementById("zmmtg-root").style.display = "block";

      const onSuccess = (success) => {
        const body = {
          signature,
          sdkKey,
          meetingNumber,
          passWord: "",
          userName: username,
          userEmail: "",
          tk: registrantToken,
          zak: zakToken,
          success: (sD) => console.log("SUCCES"),
          error: (error) => console.log(error),
        };
        ZoomMtg.join(body);
      };
      const onError = (error) => console.log("ON ERROR", error);
      ZoomMtg.init({
        leaveUrl,
        disableInvite: true,
        isSupportNonverbal: false,
        success: onSuccess,
        screenShare: false,
        enableHD: true,
        isSupportCC: false,
        isSupportChat: false,
        isSupportPolling: false,
        isSupportQA: false,
        error: onError,
        defaultView: "gallery",
      });
    },
    [meetingNumber, username]
  );

  useEffect(() => {
    const getSignature = async () => {
      try {
        let response = await fetch(authEndpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ meetingNumber, role: 0 }),
        });
        response = await response.json();
        startMeeting(response?.data?.token);
      } catch (error) {
        console.log(error);
      }
    };

    const timer = setTimeout(() => {
      if (meetingNumber) {
        getSignature();
      }
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [meetingNumber, startMeeting]);

  return (
    <div className="app">
      <div className="first-div">
        <img
          src={logo}
          style={{ width: "80%", marginTop: 12, marginBottom: 40 }}
          alt="Logo"
        />
        <p className="happiness">
          A HAPPINESS & WELLNESS <br />
          ONLINE ENGAGEMENT PLATFORM
        </p>
        <p id="connecting">
          CONNECTING
          <img
            src={loader}
            id="loader-gif"
            alt="loader"
            style={{ height: 100, marginLeft: "-10%" }}
          />
        </p>
        <p className="souls_club">The smiling souls club</p>

        <div className="email_site">
          <a href="mailto: happy@thesmilingsouls.com">
            <i class="fa fa-envelope"></i>
            happy@thesmilingsouls.com
          </a>
          <a
            href="https://thesmilingsouls.com"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa fa-globe"></i>
            www.thesmilingsouls.com
          </a>
        </div>
      </div>
      <div className="second-div">
        <img
          src={image}
          alt="old-couple-img"
          style={{ width: "100%", objectFit: "cover" }}
        />
      </div>
    </div>
  );
};

export default App;
